import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const CrowFundingPage: React.FC<PageProps> = ({ location, pageContext }) => (
  <Layout id="content" subtitle="株式会社EmbodyMe 株式投資型クラウドファンディング実施および説明会開催のお知らせ" pageContext={pageContext}>
    <section id="top">
      <Header location={location} pageContext={pageContext} />
    </section>

    <div className="content-wrap">
      <section className="privacy">
        <article>
          <h2>株式会社EmbodyMe 株式投資型クラウドファンディング実施および説明会開催のお知らせ
</h2>
          <p>&nbsp;</p>
          <p>
          株式会社EmbodyMe（本社：東京都新宿区、代表取締役：吉田 一星）はこの度、株式会社FUNDINNO様にて、「株式投資型クラウドファンディング」による資金調達を実施することとなりました。
          </p>
          <p>
          募集ページ：<a style={{color:"blue"}} href='https://fundinno.com/projects/528'>https://fundinno.com/projects/528</a>
          </p>
          <p>
          つきましては、下記日程・要領にて今回の募集に関する説明会を実施させていただきます。
          </p>
          <p>
          本件にご興味がおありになる方は、この機会にぜひご参加いただき、弊社および弊社が実施する今回の募集についてのご理解を深めていただければ幸いです。
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>株式投資型クラウドファンディング説明会</li>
          </ul>
          <p>
          弊社および弊社の事業内容を代表取締役の吉田より解説したのち、皆さまからいただいたご質問に対して、直接ご回答させていただく説明会になります。
          </p>
          <ul>
            <li>日程</li>
          </ul>
          <ol>
            <li>
            （第１回：2024年3月7日（木）19:00〜20:00）
            </li>
            <li>
            （第２回：2024年3月8日（金）18:00〜19:00）
            </li>
            <li>
            （第３回：2024年3月9日（土）14:00〜15:00）  
            </li>
            <li>
            （第４回：2024年3月10日（日）11:00〜12:00）  
            </li>
          </ol>
          <ul>
            <li>説明会会場URL</li>
          </ul>
          <p>
            <a style={{color:"blue"}} href='https://us02web.zoom.us/j/3448360250?pwd=elFrVmt3VnFWbDlGQlpsSk5BaWFBQT09'>https://us02web.zoom.us/j/3448360250?pwd=elFrVmt3VnFWbDlGQlpsSk5BaWFBQT09</a>
          </p>
          <p>
          Meeting ID: 344 836 0250
          </p>
          <p>
          Passcode: 8888
          </p>
          <p>
          ※ Zoomでの開催となります。
          </p>
          <p>
          ※ 会場URLは<strong>各回共通</strong>です。
          </p>  
          <ul>
            <li>注意事項</li>
          </ul>
          <p>
          ※ 発言などは、主催者が指名した際のみでお願いいたします。
          </p>
          <p>
          ※ FUNDINNOの募集ページに記載しております開示情報は、FUNDINNO投資家登録されている方しか閲覧できない情報もございますので、本説明会内では共有できない内容がございます。ご質問などには個別に回答させていただくか、FUNDINNOへのご質問の解答欄に回答させていただく場合がございます。あらかじめご了承ください。
          </p>
          <p>
          ※ 画面オフの参加でも問題ございません。ただし、発言時以外はミュートにてお待ちください。
          </p>
          <p>
          ※ 急な開催となったため少人数でのご参加を見込んでおりますが、ご参加人数によっては皆様との意見交換などができない場合がございます。
          </p>
          <p>
          ※ Zoomのコメントで適宜コメント・ご質問を受け付けております。
          </p>
          <p>
          ※ 終了の時間は予定です。
          </p>
          <p>
          ※ 途中入室は可能ですが、予定時刻より前に終了している場合がございます。
          </p>
          <p>
          ※ 主催側の指示に従っていただけない場合、強制退場させていただく場合がございます。
          </p>
          <p>
          以上
          </p>
          <p>
          何卒、ご参加のご検討のほどよろしくお願いいたします。
          </p>
          <p>
          株式会社EmbodyMe<br/>
          代表取締役<br/>
          吉田 一星
          </p>
        </article>
      </section>
    </div>

    <Footer location={location} />
  </Layout>
)

export default CrowFundingPage
